import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import Mustache from 'mustache';
import * as clipboard from 'clipboard-polyfill';
import http from '../http';
import { QueryClient, useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import getLocalsExport from './textualExport';
import * as CLIP from './layout/clip';
import {
  alphabeticSort,
  dateSort,
  generatePayloadBilan,
  healthStructureServiceData,
  MEDIC_ROUTES,
  MEDIC_UNITS,
} from './utils';
import CurrentUserContext from '../CurrentUserContext';
import {
  corpulsLiveDataUrl,
  hasIndividualOrEquivalentPerm,
  INDIVIDUAL_PERMISSIONS,
  isAbortError,
  isFormation,
  triggerDownload,
} from '../utils';
import { data as syoData, utils as syoUtils } from '@nfsave/syo-bilan';
import { isEqual, isEqualWith } from 'lodash';
import BilanAdaptative, {
  LAYOUT_ABCDE_FNSPF,
  LAYOUT_MARCHE,
  LAYOUT_MED,
  LAYOUT_MED_V2,
  LAYOUT_NRC,
  LAYOUT_SCCARR,
  LAYOUT_REGARDS,
  LAYOUT_XABCDE,
} from './BilanAdaptative';
import { v4 as uuidV4 } from 'uuid';

import styled from 'styled-components';
import LoaderBarContext from '../ui/useLoaderBar';
import PageHeader, { Actions, SubActions, Subtitle, Title } from '../ui/PageHeader';
import Button, { LinkButton } from '../ui/Button';
import FormGroup from '../ui/FormGroup';
import Label from '../ui/Label';
import Input from '../ui/Input';
import Select from '../ui/Select';
import { Column, Container, Row } from '../ui/FlexGrid';
import theme from '../ui/theme';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HistoryIcon from '@mui/icons-material/History';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DomainIcon from '@mui/icons-material/Domain';
import ShareIcon from '@mui/icons-material/Share';
import CorpulsEkgList from './CorpulsEkgList';
import AttachmentsList from './AttachmentsList';
import HistoryBilan from './HistoryBilan';
import PrescriptionBilan from './PrescriptionBilan';
import OrganizationsList from './OrganizationsList';
import SideBarDyn from './SideBarDyn';
import LoadingSpinner from '../ui/LoadingSpinner';
import RelevantFacts from './RelevantFacts';
import { OpenInNew } from '@mui/icons-material';
import { ErrorBox, ErrorLink, StyledContent } from '../ui/ErrorBox';
import ConfirmModal from '../components/ConfirmModal';
import CotationMedicale from './CotationMedicale';
import { Disclosure } from '../ui/Disclosure';
import Textarea from '../ui/Textarea';
import ColorizeIcon from '@mui/icons-material/Colorize';

const FloatingHeader = styled(PageHeader)`
  position: relative;
  z-index: 9;
  @media (min-width: 768px) {
    position: sticky;
    top: 0;
    background-color: ${theme.backgroundColor};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const PriorityBand = styled.div`
  position: absolute;
  height: 100%;
  width: ${theme.thin};
  top: 0px;
  left: 0px;
  background-color: ${props => (props.color ? theme[props.color] : theme.backgroundColor)};
`;

const SubHeader = styled(Container)`
  position: relative;
  z-index: 2;

  &.medecine {
    position: sticky;
    top: 148px;
    border-radius: 15px;
    background-color: ${theme.backgroundColor} !important;
    width: 20%;
    height: fit-content;
    margin-left: 20px;
    margin-right: 0px;
    margin-top: 35px;
  }

  @media (max-width: 1080px) {
    z-index: 1;
    > div {
      flex-direction: column;
    }
  }
`;

const EvacuateButton = styled.button`
  background: transparent;
  border: none;
  color: #4786ff;
  display: inline;

  :hover {
    text-decoration: underline;
  }
`;

const RowCompliance = styled.div`
  .compliance {
    width: 30%;
  }

  @media (max-width: 1200px) {
    .compliance {
      width: 50%;
    }
  }
  @media (max-width: 800px) {
    .compliance {
      width: 100%;
    }
  }
`;

const ConformityHistory = styled.div`
  display: flex;
  padding: ${theme.thin} 0;
  gap: ${theme.thin} ${theme.small};
  flex-wrap: wrap;

  &:not(:last-child) {
    border-bottom: 1px solid white;
  }

  p {
    margin: 0;
  }

  svg {
    color: ${theme.grey3};

    &.new {
      color: ${theme.blue};
    }

    &.empty {
      color: ${theme.grey7};
    }

    &.pui- {
      &negative {
        color: ${theme.red};
      }

      &positive {
        color: ${theme.green};
      }

      &empty {
        color: ${theme.grey7};
      }
    }
  }
`;

const Pre = styled.td`
  white-space: pre-wrap;
`;

const TableHeaderGrey = styled.tr`
  background-color: #f2f3f4;
`;

const TableHeaderLightGrey = styled.th`
  padding-left: 0.2rem;
  padding-top: 0.4rem;
  padding-bottom: 0.15rem;
  background-color: #f8f9f9;
  border-top: 2px solid white;
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: ${theme.small};

  thead {
    tr:hover {
      background-color: transparent;
    }
  }

  tr {
    border-bottom: 2px solid ${theme.blueBilan};

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: ${theme.blueBilan};
    }
  }
`;

const EMPTY_COMPLIANCE = {
  value: 0,
  label: 'Non renseigné',
  kind: 'empty',
};

const ID_SAMU_02 = 459;
const ID_SAMU_06 = 948;
const ID_SAMU_17 = 478;
const ID_SDIS_17 = 236;
const ID_SAMU_22 = 179;
const ID_SAMU_28 = 234;
const ID_SDIS_28 = 924;
const ID_SAMU_13 = 1032;
const ID_SAMU_13_FORMA = 416;
const ID_SAMU_28_FORMA = 363;
const ID_SDIS_28_FORMA = 223;
const ID_SAMU_35 = 2;
const ID_SAMU_37 = 713;
const ID_SAMU_38 = 88;
const ID_SDIS_38 = 47;
const ID_SAMU_41 = 199;
const ID_SAMU_44 = 146;
const ID_SAMU_47 = 634;
const ID_SAMU_49 = 158;
const ID_SAMU_50 = 563;
const ID_SAMU_53 = 186;
const ID_SAMU_56 = 449;
const ID_SAMU_57 = 903;
const ID_SAMU_67 = 635;
const ID_SAMU_71 = 723;
const ID_SAMU_72 = 529;
const ID_SAMU_73 = 643;
const ID_SAMU_79 = 45;
const ID_SAMU_83 = 899;
const ID_SAMU_83_FORMA = 219;
const ID_SAMU_85_FORMA = 84;

const ID_ASSU_17_ST_BERNARD = 138;

const ID_NFSAVE_DEMO = 98;
const ID_TILDEV_DEMO_FRED = 574;
const ID_TILDEV_DEMO_VIRGINIE = 575;
const ID_TILDEV_DEMO_ROMAIN = 719;

// const ID_TILDEV_DEMO_FLORIAN = 580;

function getLinebreak() {
  if (navigator.userAgent.indexOf('Windows') !== -1) {
    return '\r\n';
  }
  return '\n';
}

function ShowBilanComponent({ match, router, scope = 'test' }) {
  const hisPui = scope === 'pui';
  const bilanId = match.params.bilanId;
  const interventionId = match.params.interventionId;

  const queryClient = new QueryClient();
  const controller = new AbortController();

  // STALE -> ON_UPDATE -> WAIT_REFRESH
  const [bilanState, setBilanState] = useState('STALE');
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [callbackAction, setCallbackAction] = useState(null);

  const [toastMsg, setToastMsg] = useState([]);
  const toastId = useRef(null);
  const toastOptions = {
    onClose: () => setToastMsg([]),
    type: toast.TYPE.WARNING,
    autoClose: 5000,
    toastId: 'toastMsg',
    hideProgressBar: false,
  };
  const toastContent = (
    <div>
      Une erreur est survenue pendant le rafraichissement&thinsp;:
      <ul>
        {toastMsg.map((msg, idx) => (
          <li key={idx}>{msg.message}</li>
        ))}
      </ul>
    </div>
  );

  const updateToastMsg = () => {
    if (toastMsg.length === 0) return;
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast(toastContent, toastOptions);
    } else {
      toast.update(toastId.current, {
        render: () => toastContent,
        ...toastOptions,
      });
    }
  };

  const addToastMsg = (id, msg) => {
    let temp = toastMsg;
    let index = temp.findIndex(i => i.id === id);
    if (index === -1) {
      temp.push({ id: id, message: msg });
    } else {
      temp[index].message = msg;
    }
    setToastMsg([...temp]);
  };

  useEffect(() => {
    updateToastMsg();
  }, [toastMsg]);

  const {
    isLoading: bilanIsLoading,
    isFetching: bilanIsFetching,
    data: bilan,
    refetch: bilanRefetch,
    isError: bilanIsError,
  } = useQuery(
    ['bilanId'],
    async () => {
      return await http
        .get(`bilans/${encodeURIComponent(bilanId)}.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          setNumeroDossier(res.info_tech.conformite);
          setNumeroVictime(res.info_tech.conformite_id);
          setHopitalId(res.devenir.hopital_id);

          if (res.permissions.length !== 0 && hasOriginalPerm() && !assessmentLayoutTab.some(tab => tab.value === 'oriA')) {
            let layout = LAYOUT_NRC;
            if (hasSccarrPerm(res)) {
              layout = LAYOUT_SCCARR;
            } else if (hasRegardsPerm(res)) {
              layout = LAYOUT_REGARDS;
            } else if (hasMarchePerm(res)) {
              layout = LAYOUT_MARCHE;
            } else if (hasXabcdePerm(res)) {
              layout = LAYOUT_XABCDE;
            } else if (hasAbcdePerm(res)) {
              layout = LAYOUT_ABCDE_FNSPF;
            } else if (hasMedicalPerm(res)) {
              layout = LAYOUT_MED;
            }

            const oriLayoutTab = { value: 'oriA', label: 'Original adaptatif', adaptative: true, layout: layout };
            setAssessmentLayoutTab(
              [
                oriLayoutTab,
                { value: 'oriS', label: 'Original statique', adaptative: false, layout: layout },
                ...assessmentLayoutTab,
              ],
            );

            setAssessmentLayoutCurrent(oriLayoutTab);
          }

          refetchBilans();
          return res;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          addToastMsg('bilanId', 'du bilan');
          throw error;
        });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !confirmationIsOpen,
    },
  );
  const { isLoading: hopitalsIsLoading, data: hopitals } = useQuery(
    ['hopitals'],
    async () => {
      return await http
        .get(`hopital`, {
          signal: controller.signal,
        })
        .json()
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          addToastMsg('hopitals', 'des hopitaux');
          throw error;
        });
    },
    {
      cacheTime: 0,
      enabled: !confirmationIsOpen,
    },
  );

  const { data: bilans, refetch: refetchBilans } = useQuery(
    ['bilansHistory'],
    async () => {
      return await http
        .get(`bilans/historique.json`, {
          signal: controller.signal,
          searchParams: {
            id: interventionId,
          },
        })
        .json()
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          addToastMsg('bilanHistory', 'de l\'historique');
          throw error;
        });
    },
    {
      cacheTime: 0,
      refetchInterval: scope !== 'pui' ? 5000 : 0,
      enabled: !confirmationIsOpen,
      onSuccess: data => {
        // Si une demande de confirmation d'action est en cours
        if (confirmationIsOpen === true) return;

        if (
          !isEqualWith(data, bilanHistory, (a, b) => a.id && b.id) &&
          bilanHistory.length > 0 &&
          bilanState !== 'ON_UPDATE'
        ) {
          if (data[0].id !== bilan.id) toast.info('Un bilan plus récent est disponible');
          setBilanState('STALE');
        }
        setLastBilan(data[0]);
        setBilanHistory(data);
      },
    },
  );

  const { data: orgaAssessment } = useQuery(
    ['orgaAssessment'],
    async () => {
      return await http
        .get(`interventions/${interventionId}/transmissions.json`, {
          signal: controller.signal,
        })
        .json()
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          addToastMsg('orgaAssessment', 'des organisations ayant accès au bilan');
          throw error;
        });
    },
    {
      cacheTime: 0,
      refetchInterval: hisPui ? 0 : 5000,
      enabled: !confirmationIsOpen,
    },
  );

  const { currentUser } = useContext(CurrentUserContext);
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [assessmentLayoutCurrent, setAssessmentLayoutCurrent] = useState({
    value: 'nrcA',
    label: 'NRC adaptatif',
    adaptative: true,
    layout: LAYOUT_NRC,
  });
  const [bilanHistory, setBilanHistory] = useState([]);
  const [lastBilan, setLastBilan] = useState(null);
  /**
   * Used when exporting or saving a check-up.
   * Used to avoid displaying the update button between two refreshes
   */
  const [numeroDossier, setNumeroDossier] = useState(null);
  const [numeroVictime, setNumeroVictime] = useState(null);
  const [adaptativeEdit, setAdaptativeEdit] = useState([]);
  const [hopitalId, setHopitalId] = useState(null);
  const [healthStructureId, setHealthStructureId] = useState(null);
  const [propositionEvacuationName, setPropositionEvacuationName] = useState(null);
  const [propositionEvacuation, setPropositionEvacuation] = useState(null);

  useEffect(() => {
    if (hopitalId == null) {
      return setHealthStructureId(null);
    }
    if (hopitals === undefined) return setHealthStructureId(null);
    const healthService = hopitals.find(h => h.id === hopitalId);
    if (healthService == null) {
      return setHealthStructureId(null);
    }
    return setHealthStructureId(healthService.organization.id);
  }, [hopitalId, hopitals, bilan]);

  useEffect(() => {
    bilanRefetch();
  }, [match]);

  const healthStructureService = useMemo(() => {
    if (!bilan) return;
    return healthStructureServiceData(currentUser, hopitals || [], healthStructureId, bilan.en_cours);
  }, [currentUser, hopitals, healthStructureId, bilan]);

  async function updateBilan(enCours, sheetForm = null) {
    const payload = generatePayloadBilan('BILAN', bilan, enCours, hopitalId, numeroDossier, numeroVictime);

    if (
      (hasLiaisonFormPerm(currentUser) || hasCompteRenduSmurPerm(currentUser) || hasTrasportPerm(currentUser)) &&
      sheetForm !== null
    ) {
      payload.sheet = [
        {
          id: uuidV4(),
          ...sheetForm,
        },
      ];
    }

    if (adaptativeEdit.length > 0) {
      adaptativeEdit.forEach(edit => {
        const path = edit.path.split('.');
        switch (path.length) {
          case 1:
            payload[path[0]] = edit.value;
            break;
          case 2:
            payload[path[0]][path[1]] = edit.value;
            break;
          case 3:
            payload[path[0]][path[1]][path[2]] = edit.value;
            break;

          default:
            break;
        }
      });
    }
    return await http.post(`bilans`, { json: payload }).json();
  }

  const saveBilan = async () => {
    setConfirmationIsOpen(false);
    const payload = await updateBilan(bilan.en_cours);
    setAdaptativeEdit([]);
    setBilanState('WAIT_REFRESH');
    setCallbackAction(null);
    await router.replace({
      pathname: `/interventions/${bilan.intervention.numero}/bilans/${payload.id}`,
    });
    setLoaderBar(false);
  };

  const restoreBilan = async () => {
    setConfirmationIsOpen(false);
    await updateBilan('yes');
    setAdaptativeEdit([]);
    setBilanState('WAIT_REFRESH');
    setCallbackAction(null);
    setLoaderBar(false);
    router.push({
      pathname: `/interventions/active`,
    });
  };

  const archiveBilan = async () => {
    setConfirmationIsOpen(false);
    await updateBilan('no');
    setAdaptativeEdit([]);
    setBilanState('WAIT_REFRESH');
    setCallbackAction(null);
    setLoaderBar(false);
    router.push({
      pathname: `/interventions/active`,
    });
  };

  const exportBilan = async () => {
    setConfirmationIsOpen(false);
    const locals = getLocalsExport(bilan, numeroDossier, hopitals, hopitalId, currentUser.organization_id, currentUser);

    try {
      let template = CLIP.CLIP_TEXT_TEMPLATE;
      if (
        currentUser.organization_id === ID_SAMU_44 ||
        currentUser.organization_id === ID_SAMU_41 ||
        currentUser.organization_id === ID_SAMU_22 ||
        currentUser.organization_id === ID_SAMU_79
      ) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_44;
      }
      if (currentUser.organization_id === ID_SAMU_49) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_49;
      }
      if (currentUser.organization_id === ID_SAMU_47) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_47;
      }
      if (currentUser.organization_id === ID_SAMU_53 || currentUser.organization_id === ID_SAMU_71) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_53_NEW;
      }
      if (currentUser.organization_id === ID_SAMU_17 || currentUser.organization_id === ID_SDIS_17) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_17;
      }
      if (
        currentUser.organization_id === ID_SAMU_02 ||
        currentUser.organization_id === ID_TILDEV_DEMO_ROMAIN
      ) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_02;
      }
      if (currentUser.organization_id === ID_SAMU_57) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_57;
      }
      if ((isFormation() && currentUser.organization_id === ID_SAMU_13_FORMA) || currentUser.organization_id === ID_SAMU_13) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_13;
      }
      if (
        (isFormation() && currentUser.organization_id === ID_SAMU_28_FORMA) ||
        (isFormation() && currentUser.organization_id === ID_SDIS_28_FORMA) ||
        currentUser.organization_id === ID_SAMU_28 ||
        currentUser.organization_id === ID_SDIS_28
      ) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_28;
      }
      if (currentUser.organization_id === ID_SAMU_35) {
        template = CLIP.CLIP_TEXT_TEMPLATE_COVID19;
      }
      if (currentUser.organization_id === ID_SDIS_38) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SDIS_38;
      }
      if (currentUser.organization_id === ID_SAMU_38) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_38;
      }
      if (currentUser.organization_id === ID_SAMU_72) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_72;
      }
      if (currentUser.organization_id === ID_SAMU_50) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_50;
      }
      if (currentUser.organization_id === ID_SAMU_56) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_56;
      }
      if (currentUser.organization_id === ID_SAMU_67) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_67;
      }
      if (currentUser.organization_id === ID_SAMU_73) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_73;
      }
      if (currentUser.organization_id === ID_SAMU_37) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_37_NEW;
      }
      if (currentUser.organization_id === ID_TILDEV_DEMO_FRED) {
        template = CLIP.CLIP_TEXT_TEMPLATE_NRC_ESANTE;
      }
      if (currentUser.organization_id === ID_TILDEV_DEMO_VIRGINIE) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_53_NEW;
      }
      if (currentUser.organization_id === ID_NFSAVE_DEMO) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SDIS_38;
      }
      if (isFormation() && currentUser.organization_id === ID_SAMU_85_FORMA) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_85;
      }
      if (
        (isFormation() && currentUser.organization_id === ID_SAMU_83_FORMA) ||
        currentUser.organization_id === ID_SAMU_83 ||
        currentUser.organization_id === ID_SAMU_06
      ) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_83;
      }
      if (currentUser.organization_id === ID_SAMU_06) {
        template = CLIP.CLIP_TEXT_TEMPLATE_SAMU_06;
      }
      const rendered = Mustache.render(template, locals);

      const shouldUseSystemLinebreak = () => {
        return (
          currentUser.organization_id === ID_SAMU_38 ||
          currentUser.organization_id === ID_SAMU_44 ||
          currentUser.organization_id === ID_SAMU_47 ||
          currentUser.organization_id === ID_SAMU_49 ||
          currentUser.organization_id === ID_ASSU_17_ST_BERNARD ||
          currentUser.id === 129 /* Elisabeth COLIN, CHU Rennes */
        );
      };

      await clipboard.writeText(
        shouldUseSystemLinebreak() === true ? rendered.split('\n').join(getLinebreak()) : rendered,
      );

      toast('Le contenu du bilan a été copié dans votre presse papier.', {
        type: 'info',
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      const updated = await updateBilan(bilan.en_cours);
      setBilanState('WAIT_REFRESH');

      if (currentUser.organization_id !== ID_SAMU_49) {
        if (!hasPerm('web:intervention:pdf_export:disabled-pdf-export')) {
          const conformite = ('0000' + numeroDossier).slice(-4);

          const pdfResponse = await http.get(`bilans/${encodeURIComponent(updated.id)}/export`, {
            headers: {
              Accept: 'application/pdf',
            },
          });
          const contentDisposition = pdfResponse.headers.get('content-disposition');
          const filenames = contentDisposition
            .split('; ')
            .filter(e => e.includes('filename'))
            .map(e => e.replaceAll('UTF-8\'\'', '').replaceAll('"', '').replaceAll('.pdf', ''));
          const filenamesObj = {
            default: filenames.find(e => e.includes('filename='))?.replace('filename=', '') ?? null,
            utf8: filenames.find(e => e.includes('filename*='))?.replace('filename*=', '') ?? null,
          };

          let baseFilename = '';

          try {
            baseFilename = hasPerm('web:intervention:pdf_export:rename_pdf')
              ? `${bilan.victime.nom ?? 'NR'}_${bilan.victime.prenom ?? 'NR'}_${bilan.victime.date_naissance ?? 'NR'}_${bilan.equipe.intervention_num ?? 'NR'}`
              : (filenamesObj.utf8 ?? filenamesObj.default);

            const pdfPayload = await pdfResponse.arrayBuffer();
            const pdfBlob = new Blob([pdfPayload], {
              type: 'application/pdf',
            });
            await triggerDownload(`${baseFilename}.pdf`, pdfBlob);
          } catch (err) {
            toast(`Impossible de télécharger le fichier PDF du résumé de l'intervention ${conformite}.`, {
              type: toast.TYPE.ERROR,
              autoClose: false,
            });
          }

          if (hasPerm('integrations:centaure-xv:xml-on-export')) {
            const xmlPayload = await http
                .get(`access_bilans/telechargement_xml`, {
                  searchParams: {
                    base_filename: baseFilename,
                    conformite: conformite,
                    conformite_id: numeroVictime,
                    NomVictime: bilan.victime.nom,
                    PrenomVictime: bilan.victime.prenom,
                  },
                })
                .arrayBuffer();
            // Browsers asks for a specific permission when downloading multiple files, and Firefox just
            // chokes on it, only presenting the last downloaded file. Thus, we deliberately slow down the
            // application to work around this limitation.
            await new Promise(resolve => setTimeout(resolve, 250));
            try {
              const xmlBlob = new Blob([xmlPayload], {
                type: 'text/xml',
              });
              await triggerDownload(`${baseFilename}.xml`, xmlBlob);
            } catch (err) {
              console.error(err);
              toast(`Impossible de télécharger le fichier XML pour Centaure de l'intervention ${conformite}.`, {
                type: toast.TYPE.ERROR,
                autoClose: false,
              });
            }
          }
        }
      }
      setAdaptativeEdit([]);
      setCallbackAction(null);
      router.replace({
        pathname: `/interventions/${bilan.intervention.numero}/bilans/${updated.id}`,
      });
      setLoaderBar(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoaderBar(false);
    }
  };

  const handleActionOnBilan = async (ev, action) => {
    ev.preventDefault();
    setLoaderBar(true);
    if (bilanNeedUpdate) {
      setBilanState('ON_UPDATE');
      await queryClient.cancelQueries('bilansHistory');
      switch (action) {
        case 'SAVE':
          setCallbackAction(() => saveBilan);
          break;
        case 'EXPORT':
          setCallbackAction(() => exportBilan);
          break;
        case 'RESTORE':
          setCallbackAction(() => restoreBilan);
          break;
        case 'ARCHIVE':
          setCallbackAction(() => archiveBilan);
          break;
        default:
          return;
      }
      setConfirmationIsOpen(true);
    } else {
      setBilanState('ON_UPDATE');
      switch (action) {
        case 'SAVE':
          await saveBilan();
          break;
        case 'EXPORT':
          await exportBilan();
          break;
        case 'RESTORE':
          await restoreBilan();
          break;
        case 'ARCHIVE':
          await archiveBilan();
          break;
        default:
          return;
      }
    }
  };

  /**
   * Permet la génération d'une fiche annexe
   * @param type string - Nom du type de fiche annexe à générer
   * @param freeText object - JSON transféré avec la fiche annexe
   */
  const handleSheetFormCreate = async (type, freeText) => {
    setLoaderBar(true);
    setBilanState('ON_UPDATE');
    freeText.organization_department = currentUser.departement || null;
    const temp = {
      type,
      freeText: JSON.stringify(freeText),
      sign: '',
    };
    localStorage.setItem('sheetForm', JSON.stringify(temp));
    if (bilanNeedUpdate) {
      await queryClient.cancelQueries('bilansHistory');
      setCallbackAction(() => createAnnexSheet);
      setConfirmationIsOpen(true);
    } else {
      await createAnnexSheet();
    }
  };

  const createAnnexSheet = async () => {
    setConfirmationIsOpen(false);

    try {
      const temp = localStorage.getItem('sheetForm');
      const sheetForm = temp ? JSON.parse(temp) : null;
      const payload = await updateBilan(bilan.en_cours, sheetForm);
      setAdaptativeEdit([]);
      localStorage.removeItem('sheetForm');
      setBilanState('WAIT_REFRESH');
      setCallbackAction(null);
      router.replace({
        pathname: `/interventions/${bilan.intervention.numero}/bilans/${payload.id}`,
      });
      setLoaderBar(false);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    } finally {
      setLoaderBar(false);
    }
  };

  const handleNotConfirm = () => {
    setConfirmationIsOpen(false);
    setCallbackAction(null);
    setBilanState('STALE');
    setLoaderBar(false);
  };

  /** Vérifie si l'utilisateur n'est pas sur le dernier bilan connu */
  const bilanNeedUpdate = useMemo(
    // () => (lastBilan !== null && match.params.bilanId !== lastBilan.id.toString() && !onUpdate),
    () => lastBilan !== null && match.params.bilanId !== lastBilan.id.toString() && bilanState === 'STALE',
    [lastBilan, match, bilanState],
  );
  /** Vérifie si le bilan dispose de données nécessitant une sauvegarde */
  const bilanHasNonSavedData = useMemo(
    () =>
      adaptativeEdit.length > 0 ||
      bilan?.devenir.hopital_id !== hopitalId ||
      bilan?.info_tech.conformite !== numeroDossier ||
      bilan?.info_tech.conformite_id !== numeroVictime,
    [bilan, adaptativeEdit, numeroDossier, numeroVictime, hopitalId],
  );

  const colorSaveBtn = useMemo(() => {
    if (bilanNeedUpdate || bilanHasNonSavedData) return 'not-validated';
  }, [bilanNeedUpdate, bilanHasNonSavedData]);

  const createdDate = useMemo(() => {
    return DateTime.fromISO(bilan?.intervention.created_at).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_SHORT);
  }, [bilan]);

  const suggestionEvacuation = useMemo(() => {
    return parseInt(bilan?.devenir.suggestion_ch, 10);
  }, [bilan]);

  useEffect(() => {
    if (!Number.isNaN(suggestionEvacuation) && suggestionEvacuation !== 0) {
      if (hopitals !== undefined) {
        const hopital = hopitals.find(h => h.id === suggestionEvacuation);
        if (hopital !== null && hopital !== undefined) {
          setPropositionEvacuationName(hopital.nom);
          setPropositionEvacuation(hopital);
        }
      } else {
        setPropositionEvacuationName('NR');
        setPropositionEvacuation(null);
      }
    } else {
      setPropositionEvacuationName('NR');
      setPropositionEvacuation(null);
    }
  }, [suggestionEvacuation, hopitals]);

  const handleAcceptEvacuation = async ev => {
    ev.preventDefault();
    setHealthStructureId(propositionEvacuation.organization.id);
    setHopitalId(propositionEvacuation.id);
  };

  const handleHealthStructureChange = value => {
    if (value == null) {
      setHealthStructureId(null);
      setHopitalId(null);
    } else if (healthStructureId !== value.value) {
      setHealthStructureId(value.value);
      const services = healthStructureService.healthServicesByStructure.get(value.value) || [];
      setHopitalId(services[0].id);
    }
  };

  const handleShareClick = async ev => {
    setLoaderBar(true);
    try {
      await http
        .post('share', {
          json: {
            bilan_id: bilan.id,
          },
        })
        .json();
    } catch (err) {
      console.error(err);
    } finally {
      setLoaderBar(false);
    }
  };

  const hasPerm = useCallback(
    requestedPermissionKey => {
      return syoUtils.hasPerm(currentUser, requestedPermissionKey);
    },
    [currentUser],
  );

  const hasArchivePerm = () => hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_INACTIVE'), currentUser);
  const hasDownloadPerm = () => hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_EXPORT'), currentUser);
  const hasSavePerm = () =>
    hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_ALL'), currentUser) ||
    (hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('BILAN_CREATE'), currentUser) &&
      hasPerm('interventions:list-all'));
  const hasSharePerm = () => currentUser.organization_id === 1;
  const hasDestinationReadOnly = user => syoUtils.hasPerm(user, 'web:intervention:destination-lecture-seule');
  const hasLiaisonFormPerm = user => syoUtils.hasPerm(user, 'web:fiche-liaison:atsu35');
  const hasCompteRenduSmurPerm = user => syoUtils.hasPerm(user, 'web:intervention:compte-rendu-smur');
  const hasMaterialHelicoPerm = user => syoUtils.hasPerm(user, 'assessment:annex-sheets:material_helico:visible');
  const hasTrasportPerm = user => syoUtils.hasPerm(user, 'web:facturation-transport');
  const hasOriginalPerm = () => hasPerm('web:bilan:display_in_original_config');
  const hasSccarrPerm = user =>
    syoUtils.hasPerm(user, 'web:assessment:sccarr') ||
    (syoUtils.hasPerm(user, 'assessment:abcde') && syoUtils.hasPerm(user, 'assessment:abcde:scaarr'));
  const hasRegardsPerm = user =>
    syoUtils.hasPerm(user, 'web:assessment:regard)') ||
    (syoUtils.hasPerm(user, 'assessment:regard'));
  const hasAbcdePerm = user => syoUtils.hasPerm(user, 'assessment:abcde');
  const hasXabcdePerm = user =>
    syoUtils.hasPerm(user, 'assessment:abcde') &&
    syoUtils.hasPerm(user, 'assessment:abcde-fnsp') &&
    syoUtils.hasPerm(user, 'assessment:Abcde_x');
  const hasMarchePerm = user =>
    syoUtils.hasPerm(user, 'assessment:abcde') &&
    syoUtils.hasPerm(user, 'assessment:abcde-fnsp') &&
    syoUtils.hasPerm(user, 'assessment:abcde:march');
  const hasMedicalPerm = user => syoUtils.hasPerm(user, 'assesment:isMedicalRefactor');
  const hasMedecinePerm = user => syoUtils.hasPerm(user, 'web:bilan:vue_medecine_de_regulation');

  const [assessmentLayoutTab, setAssessmentLayoutTab] = useState(() => {
    const tab = [
      { value: 'nrcA', label: 'NRC adaptatif', adaptative: true, layout: LAYOUT_NRC },
      { value: 'nrcS', label: 'NRC statique', adaptative: false, layout: LAYOUT_NRC },
      { value: 'abcdeA', label: 'ABCDE adaptatif', adaptative: true, layout: LAYOUT_ABCDE_FNSPF },
      { value: 'abcdeS', label: 'ABCDE statique', adaptative: false, layout: LAYOUT_ABCDE_FNSPF },
      { value: 'xabcdeA', label: 'XABCDE adaptatif', adaptative: true, layout: LAYOUT_XABCDE },
      { value: 'xabcdeS', label: 'XABCDE statique', adaptative: false, layout: LAYOUT_XABCDE },
      { value: 'marcheA', label: 'MARCHE adaptatif', adaptative: true, layout: LAYOUT_MARCHE },
      { value: 'marcheS', label: 'MARCHE statique', adaptative: false, layout: LAYOUT_MARCHE },
    ];

    if (hasSccarrPerm(currentUser)) {
      tab.push({
        value: 'sccarrA',
        label: 'SCCARR adaptatif',
        adaptative: true,
        layout: LAYOUT_SCCARR,
      });
      tab.push({
        value: 'sccarrS',
        label: 'SCCARR statique',
        adaptative: false,
        layout: LAYOUT_SCCARR,
      });
    }
    if (hasMedecinePerm(currentUser) && !hisPui) {
      tab.push({
        value: 'medicineA',
        label: 'MÉDECINE adaptatif',
        adaptative: true,
        layout: LAYOUT_MED_V2,
      });
      tab.push({
        value: 'medicineS',
        label: 'MÉDECINE statique',
        adaptative: false,
        layout: LAYOUT_MED_V2,
      });
    }
    if (hasMedicalPerm(currentUser)) {
      tab.push({ value: 'medA', label: 'Médical adaptatif', adaptative: true, layout: LAYOUT_MED });
      tab.push({ value: 'medS', label: 'Médical statique', adaptative: false, layout: LAYOUT_MED });
    }
    if (hasRegardsPerm(currentUser)) {
      tab.push({ value: 'regardsA', label: 'Regards adaptatif', adaptative: true, layout: LAYOUT_REGARDS });
      tab.push({ value: 'regardsS', label: 'Regards statique', adaptative: false, layout: LAYOUT_REGARDS });
    }
    return tab;
  });

  const assessmentGrouped = [
    {
      label: 'Adaptative',
      options: assessmentLayoutTab.filter(e => e.adaptative),
    },
    {
      label: 'Statique',
      options: assessmentLayoutTab.filter(e => !e.adaptative),
    },
  ];

  useEffect(() => {
    if (hasMedecinePerm(currentUser) && !hisPui) {
      setAssessmentLayoutCurrent(assessmentLayoutTab.find(h => h.value === 'medicineA'));
    } else if (hasSccarrPerm(currentUser)) {
      setAssessmentLayoutCurrent(assessmentLayoutTab.find(h => h.value === 'sccarrA'));
    } else if (hasMarchePerm(currentUser)) {
      setAssessmentLayoutCurrent(assessmentLayoutTab.find(h => h.value === 'marcheA'));
    } else if (hasXabcdePerm(currentUser)) {
      setAssessmentLayoutCurrent(assessmentLayoutTab.find(h => h.value === 'xabcdeA'));
    } else if (hasAbcdePerm(currentUser)) {
      setAssessmentLayoutCurrent(assessmentLayoutTab.find(h => h.value === 'abcdeA'));
    } else if (hasMedicalPerm(currentUser)) {
      setAssessmentLayoutCurrent(assessmentLayoutTab.find(h => h.value === 'medA'));
    } else {
      setAssessmentLayoutCurrent(assessmentLayoutTab.find(h => h.value === 'nrcA'));
    }

    return () => {
      setBilanState('STALE');
      controller.abort();
    };
  }, []);

  const configPdf = useMemo(() => {
    let permissions = bilan?.permissions || [];
    if (syoUtils.hasPerm(currentUser, 'export:use-self-permissions')) {
      permissions = currentUser.permissions.map(p => p.key);
    }
    if (bilan) {
      if (permissions.includes('assesment:isMedicalRefactor')) {
        return '(Médical)';
      } else if (permissions.includes('assessment:abcde:scaarr')) {
        return '(SCCARR)';
      } else if (permissions.includes('assessment:abcde:march')) {
        return '(Marche)';
      } else if (permissions.includes('assessment:abcde-fnsp')) {
        return '(XABCDE)';
      } else if (permissions.includes('assessment:abcde')) {
        return '(ABCDE)';
      } else {
        return '(NRC)';
      }
    }
    return '';
  }, [bilan, currentUser]);

  const getPanels = useMemo(() => {
    let panels = [];
    if (hasPerm('interventions:consult-attachments') === true) {
      if (!hasPerm('web:intervention:pieces-jointes:hide')) {
        panels = panels.concat([
          {
            key: 'assessment-attachments',
            contents: (
              <AttachmentsList
                interventionId={bilan?.intervention.numero}
                bilanId={bilan?.id}
                annex={bilan?.annex_sheets}
                liaisonFormPerm={hasLiaisonFormPerm(currentUser)}
                compteRenduSmurPerm={hasCompteRenduSmurPerm(currentUser)}
                sheetFormCallback={handleSheetFormCreate}
                materialHelicoPerm={hasMaterialHelicoPerm(currentUser)}
                transportPerm={hasTrasportPerm(currentUser)}
                hisPui={hisPui}
              />
            ),
            opts: {
              title: 'Pièces jointes',
              icon: <AttachFileIcon />,
              width: 480,
              attachments: bilan?.attachments,
            },
          },
        ]);
      }
      if (!hasPerm('web:intervention:historique:hide')) {
        panels = panels.concat([
          {
            key: 'assessment-historyBilan',
            contents: (
              <div style={{ padding: '1rem' }}>
                <h3>Historique</h3>
                <hr />
                <HistoryBilan bilans={!bilans ? [] : bilans} currentUser={currentUser} />
              </div>
            ),
            opts: {
              title: 'Historique',
              icon: <HistoryIcon />,
              width: 380,
            },
          },
        ]);
      }
    }

    if (hasPerm('web:intervention:organisation') && !hisPui) {
      panels = panels.concat([
        {
          key: 'assessment-organization',
          contents: (
            <div style={{ padding: '1rem' }}>
              <h3>Organisations</h3>
              <hr />
              <OrganizationsList
                orgaList={orgaAssessment || []}
                currentUser={currentUser}
                interventionNum={bilan?.intervention.numero}
                addToastMsg={addToastMsg}
              />
            </div>
          ),
          opts: {
            title: 'Organisations',
            icon: <DomainIcon />,
          },
        },
      ]);
    }

    if (hasPerm('web:interventions:relevant-facts')) {
      panels = panels.concat([
        {
          key: 'relevant-facts',
          contents: <RelevantFacts interventionId={bilan?.intervention.numero} />,
          opts: {
            title: 'Faits remarquables',
            width: 480,
          },
        },
      ]);
    }

    if (!hasPerm('web:intervention:corpuls:hide')) {
      if (bilan?.info_tech.corpuls_mission_id != null && bilan?.info_tech.corpuls_mission_id.length > 0) {
        panels = panels.concat([
          {
            key: 'corpuls-anonymous-livedata',
            contents: (
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <h3 style={{ padding: '1rem', paddingBottom: '1.5rem' }}>
                  Constantes en direct ({bilan?.info_tech.corpuls_mission_id}){' '}
                  <a
                    href={corpulsLiveDataUrl(currentUser)(bilan?.info_tech.corpuls_mission_id)}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <OpenInNew />
                  </a>
                </h3>
                <iframe
                  title='Intégration du visualiseur de constantes Corpuls'
                  src={corpulsLiveDataUrl(currentUser)(bilan?.info_tech.corpuls_mission_id)}
                  style={{ flex: 1 }}
                />
              </div>
            ),
            opts: {
              title: '[Corpuls] Constantes en direct',
              width: 840,
            },
          },
          {
            key: 'corpuls-ecg',
            contents: (
              <CorpulsEkgList
                interventionId={bilan?.intervention.numero}
                missionId={bilan?.info_tech.corpuls_mission_id}
              />
            ),
            opts: {
              title: '[Corpuls] ECG',
            },
          },
        ]);
      }
    }

    if (hasPerm('web:intervention:prescriptions:visible')) {
      panels = panels.concat([
        {
          key: 'assessment-prescriptions',
          contents: <PrescriptionBilan bilan={!bilan ? [] : bilan} bilans={!bilanHistory ? [] : bilanHistory} />,
          opts: {
            title: 'Prescriptions / ASU',
            icon: <VaccinesIcon />,
            width: 480,
          },
        },
      ]);
    }
    return panels;
  }, [bilans, bilan, orgaAssessment, currentUser, hasPerm]);

  const handleAdaptative = ev => {
    setAssessmentLayoutCurrent(assessmentLayoutTab.find(h => h.value === ev.value));
  };

  /**
   * Retrieves the changes made in the BilanAdaptaive.
   * Checks if the change is already existing or not and updates adaptiveEdit if necessary
   *
   * @param {Object} edit
   * @param {String} edit.path
   * @param {*}      edit.value
   */
  const handleAdaptativeEdit = edit => {
    const index = adaptativeEdit.findIndex(elem => elem.path === edit.path);
    if (edit.path === 'fiche_bilan.equipe.cotation_puy_du_fou' && edit.value === 0) edit.value = null;
    if (index === -1) {
      adaptativeEdit.push(edit);
      setAdaptativeEdit([...adaptativeEdit]);
    } else {
      const tempAdap = adaptativeEdit;
      let path = edit.path.split('.');
      /** Remove the "fiche_bilan" from the path to compare the value of the edit to the current bilan */
      if (path[0] === 'fiche_bilan') path.shift();
      switch (path.length) {
        case 1:
          if (isEqual(bilan[path[0]], edit.value)) {
            deleteOneAndSet(tempAdap, setAdaptativeEdit, index);
            return;
          }
          break;
        case 2:
          if (isEqual(bilan[path[0]][path[1]], edit.value)) {
            deleteOneAndSet(tempAdap, setAdaptativeEdit, index);
            return;
          }
          break;
        case 3:
          if (isEqual(bilan[path[0]][path[1]][path[2]], edit.value)) {
            deleteOneAndSet(tempAdap, setAdaptativeEdit, index);
            return;
          }
          break;
        default:
          return;
      }

      tempAdap[index].value = edit.value;
      setAdaptativeEdit([...tempAdap]);
    }
  };

  const handleDrmChange = ev => {
    ev.preventDefault();
    if (isNaN(ev.target.value)) return;
    if (ev.target.value.length <= 9) setNumeroDossier(parseInt(ev.target.value, 10) || 0);
  };

  const deleteOneAndSet = (tab, callback, index) => {
    tab.splice(index, 1);
    callback([...tab]);
  };

  const adaptativeBilanMemo = useMemo(() => {
    if (bilan !== null && bilan !== undefined) {
      return (
        <BilanAdaptative
          bilan={bilan}
          layout={assessmentLayoutCurrent.layout}
          adaptative={assessmentLayoutCurrent.adaptative}
          setEdit={handleAdaptativeEdit}
        />
      );
    }
  }, [bilan, assessmentLayoutCurrent]);

  const getPriorityTitle = useMemo(() => {
    if (!bilan || (!bilan.priorisation && !bilan.priorisation_v2)) return '';
    return syoUtils.getPriorityText(bilan);
  }, [bilan]);

  const getPrioritycolor = useMemo(() => {
    if (!bilan || (!bilan.priorisation && !bilan.priorisation_v2)) return '';
    return syoUtils.getPriorityColor(bilan);
  }, [bilan]);

  const getSmurMission = useMemo(() => {
    switch (bilan?.info_tech?.smur_mission || bilan?.circonstance.type_mission) {
      case 1:
        return 'primaire';
      case 2:
        return 'secondaire';
      case 3:
        return 'de renfort';
      default:
        return '';
    }
  }, [bilan]);

  const subHeader = () => {
    return assessmentLayoutCurrent.layout === LAYOUT_MED_V2 ? (
      <SubHeader className='medecine'>
        <Row style={{ paddingTop: '1rem', marginBottom: '-1rem' }}>
          <Column>
            <FormGroup>
              <dt>Numéro primo-intervenant</dt>
              <dd style={{ paddingTop: '.4rem' }}>{bilan.equipe.intervention_num}</dd>
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column>
            <FormGroup>
              {hisPui ? (
                <>
                  <dt>Numéro de dossier</dt>
                  <dd style={{ paddingTop: '.4rem' }}>{numeroDossier}</dd>
                </>
              ) : (
                <>
                  <Label htmlFor='numero-dossier-input'>Numéro de dossier DRM</Label>
                  <Input
                    id='numero-dossier-input'
                    data-sentry-id='numero-dossier-input'
                    type='text'
                    inputMode='numeric'
                    pattern='\d*'
                    value={numeroDossier === 0 ? '' : numeroDossier}
                    disabled={loaderBarState}
                    onChange={ev => handleDrmChange(ev)}
                    placeholder='chiffres uniquement'
                  />
                </>
              )}
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column>
            <FormGroup>
              {hisPui ? (
                <>
                  <dt>Numéro de victime</dt>
                  <dd style={{ paddingTop: '.4rem' }}>{numeroVictime}</dd>
                </>
              ) : (
                <>
                  <Label htmlFor='numero-victime-input'>Numéro de victime</Label>
                  <Input
                    id='numero-victime-input'
                    data-sentry-id='numero-victime-input'
                    type='number'
                    value={numeroVictime}
                    disabled={loaderBarState}
                    onChange={ev => setNumeroVictime(parseInt(ev.target.value, 10) || 0)}
                  />
                </>
              )}
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column>
            <FormGroup>
              {hisPui ? (
                <></>
              ) : (
                <>
                  <dt>Identité de la victime</dt>
                  <dd style={{ paddingTop: '.4rem' }}>
                    {bilan.victime.prenom || bilan.victime.nom ? `${bilan.victime.prenom} ${bilan.victime.nom}` : 'NR'}
                  </dd>
                </>
              )}
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column>
            <dt>Proposition d'évacuation</dt>
            {hisPui || hasDestinationReadOnly(currentUser) ? (
              <dd style={{ paddingTop: '.4rem' }}>{propositionEvacuationName}</dd>
            ) : (
              <dd style={{ paddingTop: '.4rem' }}>
                {propositionEvacuationName}
                {healthStructureId == null && hopitalId == null && propositionEvacuation != null && (
                  <EvacuateButton
                    disabled={loaderBarState}
                    onClick={handleAcceptEvacuation}
                    data-sentry-id='propositionEvacuation'
                  >
                    Accepter
                  </EvacuateButton>
                )}
              </dd>
            )}
          </Column>
        </Row>
        <Row>
          <Column width={3}>
            <FormGroup>
              <Label htmlFor='select2'>Hôpital</Label>
              {hisPui || hasDestinationReadOnly(currentUser) ? (
                <Row>
                  <Column>
                    <dd>
                      {healthStructureService.healthStructureOptions.find(h => h.value === healthStructureId)?.label ||
                        'NR'}
                    </dd>
                  </Column>
                  <Column style={{ marginTop: '.4rem' }}>
                    <dd>
                      {healthStructureService.healthServicesForCurrentStructure.find(h => h.value === hopitalId)
                        ?.label || 'NR'}
                    </dd>
                  </Column>
                </Row>
              ) : (
                <>
                  <Row>
                    <Column>
                      <Select
                        isClearable
                        isSearchable
                        isDisabled={loaderBarState}
                        options={healthStructureService.healthStructureOptions}
                        value={
                          healthStructureService.healthStructureOptions.find(h => h.value === healthStructureId) || null
                        }
                        onChange={handleHealthStructureChange}
                        placeholder='Hôpital'
                        data-sentry-id='list-hopital'
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column style={{ marginTop: '.4rem' }}>
                      <Select
                        isClearable
                        isSearchable
                        isDisabled={loaderBarState || healthStructureId == null}
                        options={healthStructureService.healthServicesForCurrentStructure}
                        value={
                          healthStructureService.healthServicesForCurrentStructure.find(h => h.value === hopitalId) ||
                          null
                        }
                        onChange={value => {
                          if (value == null) {
                            setHopitalId(null);
                          } else {
                            setHopitalId(value.value);
                          }
                        }}
                        placeholder='Service'
                        data-sentry-id='list-hopital-service'
                      />
                    </Column>
                  </Row>
                </>
              )}
            </FormGroup>
          </Column>
        </Row>
      </SubHeader>
    ) : (
      <SubHeader>
        <Row style={{ paddingTop: '1rem', marginBottom: '-1rem' }}>
          <Column>
            <FormGroup>
              <dt>Numéro primo-intervenant</dt>
              <dd style={{ paddingTop: '.4rem' }}>{bilan.equipe.intervention_num}</dd>
            </FormGroup>
          </Column>
          <Column>
            <FormGroup>
              {hisPui ? (
                <>
                  <dt>Numéro de dossier</dt>
                  <dd style={{ paddingTop: '.4rem' }}>{numeroDossier}</dd>
                </>
              ) : (
                <>
                  <Label htmlFor='numero-dossier-input'>Numéro de dossier DRM</Label>
                  <Input
                    id='numero-dossier-input'
                    data-sentry-id='numero-dossier-input'
                    type='text'
                    inputMode='numeric'
                    pattern='\d*'
                    value={numeroDossier === 0 ? '' : numeroDossier}
                    disabled={loaderBarState}
                    onChange={ev => handleDrmChange(ev)}
                    placeholder='chiffres uniquement'
                  />
                </>
              )}
            </FormGroup>
          </Column>
          <Column>
            <FormGroup>
              {hisPui ? (
                <>
                  <dt>Numéro de victime</dt>
                  <dd style={{ paddingTop: '.4rem' }}>{numeroVictime}</dd>
                </>
              ) : (
                <>
                  <Label htmlFor='numero-victime-input'>Numéro de victime</Label>
                  <Input
                    id='numero-victime-input'
                    data-sentry-id='numero-victime-input'
                    type='number'
                    value={numeroVictime}
                    disabled={loaderBarState}
                    onChange={ev => setNumeroVictime(parseInt(ev.target.value, 10) || 0)}
                  />
                </>
              )}
            </FormGroup>
          </Column>
          <Column>
            <dt>Proposition d'évacuation</dt>
            {hisPui || hasDestinationReadOnly(currentUser) ? (
              <dd style={{ paddingTop: '.4rem' }}>{propositionEvacuationName}</dd>
            ) : (
              <dd style={{ paddingTop: '.4rem' }}>
                {propositionEvacuationName}
                {healthStructureId == null && hopitalId == null && propositionEvacuation != null && (
                  <EvacuateButton
                    disabled={loaderBarState}
                    onClick={handleAcceptEvacuation}
                    data-sentry-id='propositionEvacuation'
                  >
                    Accepter
                  </EvacuateButton>
                )}
              </dd>
            )}
          </Column>
          <Column width={3}>
            <FormGroup>
              <Label htmlFor='select2'>Hôpital</Label>
              {hisPui || hasDestinationReadOnly(currentUser) ? (
                <Row>
                  <Column>
                    <dd>
                      {healthStructureService.healthStructureOptions.find(h => h.value === healthStructureId)?.label ||
                        'NR'}
                    </dd>
                  </Column>
                  <Column>
                    <dd>
                      {healthStructureService.healthServicesForCurrentStructure.find(h => h.value === hopitalId)
                        ?.label || 'NR'}
                    </dd>
                  </Column>
                </Row>
              ) : (
                <Row>
                  <Column>
                    <Select
                      isClearable
                      isSearchable
                      isDisabled={loaderBarState}
                      options={healthStructureService.healthStructureOptions}
                      value={
                        healthStructureService.healthStructureOptions.find(h => h.value === healthStructureId) || null
                      }
                      onChange={handleHealthStructureChange}
                      placeholder='Hôpital'
                      data-sentry-id='list-hopital'
                    />
                  </Column>
                  <Column>
                    <Select
                      isClearable
                      isSearchable
                      isDisabled={loaderBarState || healthStructureId == null}
                      options={healthStructureService.healthServicesForCurrentStructure}
                      value={
                        healthStructureService.healthServicesForCurrentStructure.find(h => h.value === hopitalId) ||
                        null
                      }
                      onChange={value => {
                        if (value == null) {
                          setHopitalId(null);
                        } else {
                          setHopitalId(value.value);
                        }
                      }}
                      placeholder='Service'
                      data-sentry-id='list-hopital-service'
                    />
                  </Column>
                </Row>
              )}
            </FormGroup>
          </Column>
        </Row>
      </SubHeader>
    );
  };

  return (
    <div
      style={{ display: 'flex', width: '100%' }}
      className={assessmentLayoutCurrent.layout === LAYOUT_MED_V2 ? 'medecine' : ''}
    >
      {bilanIsLoading || hopitalsIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <>
          {bilanIsError ? (
            <StyledContent>
              <ErrorBox>
                <h3>Une erreur est survenue lors du traitement de votre requête</h3>
                <p>Nos équipes ont été notifiées et s'occuperont de votre problème au plus vite.</p>
                <ErrorLink to='/'>Retourner à l'accueil</ErrorLink>
              </ErrorBox>
            </StyledContent>
          ) : (
            <SideBarDyn
              panels={getPanels}
              hasSide={
                hasPerm('web:interventions:relevant-facts') ||
                hasPerm('interventions:consult-attachments') ||
                hasPerm('web:intervention:organisation')
              }
            >
              <FloatingHeader>
                {hasPerm('web:intervention:priorite') && (
                  <PriorityBand color={getPrioritycolor} title={getPriorityTitle} />
                )}
                <Title>{bilan.equipe.adresse || <em style={{ fontWeight: 400 }}>Adresse non renseignée</em>}</Title>
                <Subtitle>
                  Transmission initiale le {createdDate}, par {bilan.intervention.author}{' '}
                  {bilan.info_tech?.smur_mission || bilan.circonstance.type_mission
                    ? `- Mission ${getSmurMission}`
                    : ''}
                </Subtitle>
                <Actions>
                  {bilanNeedUpdate && !hisPui && (
                    <LinkButton
                      to={`/interventions/${bilan.intervention.numero}/bilans/${lastBilan.id}`}
                      className='warn'
                      data-sentry-id='maj'
                    >
                      <RestoreIcon className='icon-left' />
                      Mettre à jour
                    </LinkButton>
                  )}
                  {hasArchivePerm() &&
                    !hisPui &&
                    (bilan.en_cours === 'yes' ? (
                      <Button
                        disabled={loaderBarState || bilanState !== 'STALE'}
                        className={colorSaveBtn}
                        onClick={ev => handleActionOnBilan(ev, 'ARCHIVE')}
                        data-sentry-id='archive'
                      >
                        <ArchiveOutlinedIcon className='icon-left' />
                        {colorSaveBtn === 'not-validated' ? 'Sauvegarder et a' : 'A'}rchiver
                      </Button>
                    ) : (
                      <Button
                        disabled={loaderBarState || bilanState !== 'STALE'}
                        className={colorSaveBtn}
                        onClick={ev => handleActionOnBilan(ev, 'RESTORE')}
                        data-sentry-id='restore'
                      >
                        <RestoreIcon className='icon-left' />
                        {colorSaveBtn === 'not-validated' ? 'Sauvegarder et r' : 'R'}estaurer
                      </Button>
                    ))}
                  {hasDownloadPerm() && (
                    <Button
                      disabled={loaderBarState || bilanState !== 'STALE'}
                      className={colorSaveBtn}
                      onClick={ev => handleActionOnBilan(ev, 'EXPORT')}
                      data-sentry-id='export'
                    >
                      <FileCopyOutlinedIcon className='icon-left' />
                      {colorSaveBtn === 'not-validated' ? 'Sauvegarder et e' : 'E'}xporter {configPdf}
                    </Button>
                  )}
                  {hasSavePerm() && !hisPui && (
                    <Button
                      disabled={loaderBarState || bilanState !== 'STALE'}
                      className={colorSaveBtn}
                      onClick={ev => handleActionOnBilan(ev, 'SAVE')}
                      data-sentry-id='save'
                    >
                      <SaveAltIcon className='icon-left' />
                      Sauvegarder
                    </Button>
                  )}
                  {hasSharePerm() && !hisPui && (
                    <Button
                      disabled={loaderBarState || bilanState !== 'STALE'}
                      onClick={handleShareClick}
                      data-sentry-id='share'
                    >
                      <ShareIcon className='icon-left' />
                      Partager
                    </Button>
                  )}
                </Actions>
                <SubActions>
                  <Select
                    options={assessmentGrouped}
                    value={assessmentLayoutTab.find(h => h.value === assessmentLayoutCurrent.value) || null}
                    onChange={value => handleAdaptative(value)}
                  />
                </SubActions>
              </FloatingHeader>

              {bilanIsFetching ? (
                <LoadingSpinner className='center vh-50' />
              ) : (
                <>
                  {hisPui && (
                    <PharmacyCompliance match={match} router={router} bilan={bilan} refetchBilan={bilanRefetch} />
                  )}

                  {hasPerm('web:bilan:cotation_medicale') && (
                    <CotationMedicale bilan={bilan} setEdit={handleAdaptativeEdit} />
                  )}
                  {hisPui ? (
                    <CotMed>
                      <Disclosure
                        title='Bilan'
                        defaultOpen={true}
                        children={
                          <>
                            {subHeader()}
                            {adaptativeBilanMemo}
                          </>
                        }
                      />
                    </CotMed>
                  ) : (
                    <>
                      {assessmentLayoutCurrent.layout === LAYOUT_MED_V2 ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            justifyContent: 'center',
                          }}
                        >
                          {subHeader()}
                          {adaptativeBilanMemo}
                        </div>
                      ) : (
                        <>
                          {subHeader()}
                          {adaptativeBilanMemo}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </SideBarDyn>
          )}
        </>
      )}
      <ConfirmModal
        label={'Êtes-vous sûr?'}
        open={confirmationIsOpen}
        onReject={handleNotConfirm}
        callback={callbackAction}
      />
    </div>
  );
}

export default ShowBilanComponent;

/**
 * Module de conformité d'usage
 */
const PharmacyCompliance = ({ bilan, refetchBilan }) => {
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);
  const { currentUser } = useContext(CurrentUserContext);
  const controller = new AbortController();

  const [formValue, setFormValue] = useState('');
  const [formDetails, setFormDetails] = useState('');

  const { data: users, isLoading: usersIsLoading } = useQuery(
    ['users'],
    async () => {
      return await http
        .get(`user`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          let temp = [];
          res.map(u => {
            temp.push({
              value: u.id,
              label: `${u.full_name}${u.matricule ? ` (${u.matricule})` : ''}${u.provider !== 'email' ? ' | AD' : ''}`,
            });
          });
          return temp.sort((left, right) => {
            const lhs = (left.label || '').toLowerCase();
            const rhs = (right.label || '').toLowerCase();
            return lhs > rhs ? 1 : -1;
          });
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des utilisateurs');
          throw error;
        });
    },
    {
      enabled:
        hasIndividualOrEquivalentPerm(INDIVIDUAL_PERMISSIONS.get('USERS_ADD'), currentUser) ||
        syoUtils.hasPerm(currentUser, 'web:bilan:auteur_filtre'),
      cacheTime: 0,
    },
  );

  const drugsList = bilan => {
    return bilan.injections.filter(i => i.deleted_at === null && !i.v2 && i.medications[0])
      .map(i => {
        i.drug = i.medications[0].name;
        i.ampoule_holder_identification = i.medications[0].ampoule_holder_identification;
        i.nursing_protocol_name = i.medications[0].nursing_protocol_name;
        return i;
      });
  };

  const injectionsV2List = bilan => {
    return alphabeticSort(
      bilan.injections.filter(i =>
        i.deleted_at === null && i.v2 && i.started_at !== null && !i.medications[0],
      ),
      (value) => value?.intervention_protocol?.name,
    );
  };

  const injectionsByProtocolList = (bilan, v2) => {
    let injectionGroupByProtocol = [{ protocolId: null, protocolName: 'Hors protocole', injections: [] }];

    let injections;
    if (v2) injections = injectionsV2List(bilan);
    else injections = drugsList(bilan);

    injections.forEach(i => {
      switch (true) {
        case (i.nursing_protocol_name && i.nursing_protocol_name !== ''):
          let p = injectionGroupByProtocol.find(it => it.protocolId === null && it.protocolName === i.nursing_protocol_name);
          if (p) p.injections.push(i);
          else injectionGroupByProtocol.push({
            protocolId: null,
            protocolName: i.nursing_protocol_name,
            injections: [i],
          });
          break;
        case (v2 && i.intervention_protocol != null):
          let p2 = injectionGroupByProtocol.find(it => it.protocolId === i.intervention_protocol.id);
          if (p2) p2.injections.push(i);
          else injectionGroupByProtocol.push(
            { protocolId: i.intervention_protocol.id, protocolName: i.intervention_protocol.name, injections: [i] },
          );
          break;
        default:
          injectionGroupByProtocol[0].injections.push(i);
      }
    });

    if (injectionGroupByProtocol[0].injections.length === 0) injectionGroupByProtocol.splice(0, 1);
    return (injectionGroupByProtocol.length === 1 && injectionGroupByProtocol[0].injections.length === 0)
      ? [] : injectionGroupByProtocol;
  };

  const prescriptionsList = bilan => {
    dateSort(bilan.prescription_headers, (value) => value.created_at)
      .flatMap(it => it?.prescriptions)
      .forEach(it => {
        if (typeof it.payload === 'string') it.payload = JSON.parse(it.payload);
      });
    return bilan.prescription_headers;
  };

  const prescriptionMedList = (bilan, state) => {
    return prescriptionsList(bilan).filter(h =>
      h.prescriptions?.some(p => p.prescription_type === 'MEDICATION')
      && (!state || h.prescriptions[0]?.last_prescription_status?.state === state),
    );
  };

  const prescriptionGestureList = (bilan, state) => {
    return prescriptionsList(bilan).filter(h =>
      h.prescriptions?.some(p => p.prescription_type === 'GESTURE')
      && (!state || h.prescriptions[0]?.last_prescription_status?.state === state),
    );
  };

  const activeMedStates = (bilan) => {
    return ['IN_PROGRESS', 'REFUSED', 'CANCELED', 'COMPLETED'].filter(s => prescriptionMedList(bilan)
      .some(h => h.prescriptions?.some(p => p.last_prescription_status.state === s)));
  };

  const activeGestureStates = (bilan) => {
    return ['IN_PROGRESS', 'REFUSED', 'CANCELED', 'COMPLETED'].filter(s => prescriptionGestureList(bilan)
      .some(h => h.prescriptions?.some(p => p.last_prescription_status.state === s)));
  };

  const stateHtml = (state, type) => {
    switch (state) {
      case 'IN_PROGRESS':
        return type === 'GESTURE' ? <span>Non réalisé</span> : <span>Non administré</span>;
      case 'REFUSED':
        return <span>Irréalisable</span>;
      case 'CANCELED':
        return <span>Annulé</span>;
      case 'COMPLETED':
        return type === 'GESTURE' ? <span>Réalisé</span> : <span>Administré</span>;
      default:
        return 'Non approuvé en intervention';
    }
  };

  const fluidsList = bilan => {
    return bilan.injections.filter(i => i.deleted_at === null && !i.v2 && i.iv_fluids[0]);
  };

  const getValue = (value, options) => {
    const tuple = options.find(([v]) => value === v);
    return tuple != null ? tuple[1] : '';
  };

  const getAuthor = conformity => {
    if (conformity.authored_by_id && users && users.length > 0) {
      return users.find(e => e.value === conformity.authored_by_id)?.label || conformity.authored_by;
    } else {
      return conformity.authored_by;
    }
  };

  const handleSubmit = async () => {
    setLoaderBar(true);
    try {
      await http.post(`interventions/${encodeURIComponent(bilan.intervention.numero)}/pharmacy_compliance`, {
        json: { value: formValue, details: formDetails },
      });
      refetchBilan();
      setFormValue('');
      setFormDetails('');
    } catch (e) {
      console.error(e);
      toast.warn('Une erreur est survenue lors de l\'enregistrement de la conformité.');
    } finally {
      setLoaderBar(false);
    }
  };

  const handleFormValueChange = ev => {
    setFormValue(ev?.value || '');
  };

  const hasRenamePerm = syoUtils.hasPerm(currentUser, 'web:interventions_pui:label_conformite_en_remarque');

  const getComplianceData = conformity => {
    if (!bilan) return EMPTY_COMPLIANCE;
    return syoData.PHARMACY_COMPLIANCE.find(oc => oc.value === conformity.value || 0);
  };

  const getPrescripteur = header => {
    return header.procuration
      ? header.procuration_name && header.procuration_name !== ''
        ? `${header.procuration_name} (RPPS : ${header.numero_rpps})`
        : 'Non renseigné'
      : `${header.user.full_name} (RPPS : ${header.numero_rpps})`;
  };

  const getAdministrateur = presc => {
    const lastStatus = presc?.status?.find(it => it.id === presc?.last_prescription_status?.id);
    if (!lastStatus) return '';
    return (
      lastStatus.administrateur_full_name && lastStatus.administrateur_full_name !== ''
        ? lastStatus.administrateur_full_name
        : lastStatus.user.full_name
        ?? '-'
    );
  };

  const getHeureAdministrationPrescription = header => {
    if (!header.prescriptions[0]?.last_prescription_status) return '-';

    let observedAt = header.prescriptions[0]?.last_prescription_status?.observed_at
      ? header.prescriptions[0]?.last_prescription_status?.observed_at
      : header.prescriptions[0]?.last_prescription_status?.created_at;

    return DateTime.fromISO(observedAt).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_SHORT);
  };

  const getQuantiteAdministrationInjection = injection => {
    const quantity = [];
    const unit = injection?.weight_dependant ? injection?.unit_numerator : injection?.unit ?? '';

    if (!injection?.flow_rate && (!injection?.can_boli || injection?.boli?.length <= 1)) {
      quantity.push(`${injection.quantity || 'NR'} ${unit}`);
    } else if (injection?.can_boli) {
      dateSort(injection.boli, (value) => value.started_at)
        .forEach(bolus => {
          const date = DateTime.fromISO(bolus.started_at).setLocale('fr-fr').toLocaleString(DateTime.TIME_24_SIMPLE);
          quantity.push(`${bolus.quantity || 'NR'} ${unit} à ${date}`);
        });
    } else {
      dateSort(injection.flows, (value) => value.started_at)
        .forEach(flow => {
          const start = DateTime.fromISO(flow.started_at).setLocale('fr-fr').toLocaleString(DateTime.TIME_24_SIMPLE);
          if (!flow.quantity || Number.parseFloat(flow.quantity) === 0) quantity.push(`En pause à partir de ${start}`);
          else quantity.push(`${flow.quantity || 'NR'} ${unit} à partir de ${start}`);
        });
    }

    return <Pre>{quantity.join('\n')}</Pre>;
  };

  const getHeureAdministrationInjection = injection => {
    const start = DateTime.fromISO(injection?.started_at)
      .setLocale('fr-fr')
      .toLocaleString(DateTime.DATETIME_SHORT);

    if (!injection?.flow_rate && (!injection?.can_boli || injection?.boli?.length <= 1)) return start;
    else if (injection?.can_boli) {
      const firstBolus = DateTime.fromISO(dateSort(injection.boli, (value) => value.started_at)[0].started_at)
        .setLocale('fr-fr')
        .toLocaleString(DateTime.DATETIME_SHORT);
      return `${injection.boli.length} boli\nEntre ${firstBolus}\nEt ${start}`;
    } else {
      const end = DateTime.fromISO(injection?.ended_at)
        .setLocale('fr-fr')
        .toLocaleString(DateTime.DATETIME_SHORT);
      return `Début ${start}\nFin ${injection.ended_at ? end : 'Non renseignée'}`;
    }
  };

  return (
    <CotMed>
      <Disclosure
        title="Conformité d'usage"
        defaultOpen={true}
        children={
          <>
            <Container>
              <form>
                <RowCompliance>
                  <FormGroup className='compliance'>
                    <Label>{hasRenamePerm ? 'Remarque / Relecture' : 'Conformité'}</Label>
                    <Select
                      isClearable
                      options={hasRenamePerm ? syoData.PHARMACY_COMPLIANCE_REMARQUE : syoData.PHARMACY_COMPLIANCE_CONFORMITE}
                      value={
                        hasRenamePerm
                          ? syoData.PHARMACY_COMPLIANCE_REMARQUE.find(pc => pc.value === formValue) || null
                          : syoData.PHARMACY_COMPLIANCE_CONFORMITE.find(pc => pc.value === formValue) || null
                      }
                      onChange={handleFormValueChange}
                      placeholder={`${hasRenamePerm ? 'Remarque' : 'Conformité'}`}
                      isDisabled={loaderBarState}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Commentaire</Label>
                    <Textarea
                      value={formDetails}
                      onChange={ev => setFormDetails(ev.target.value)}
                      disabled={loaderBarState}
                    />
                  </FormGroup>
                </RowCompliance>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    className='filled'
                    type='button'
                    onClick={() => handleSubmit()}
                    disabled={loaderBarState || formValue === null || formValue === ''}>
                    Enregistrer la {hasRenamePerm ? 'remarque' : 'conformité'}
                  </Button>
                </div>
              </form>
              <>
                <h3>Historique des {hasRenamePerm ? 'remarques' : 'conformités'}</h3>
                {bilan.pharmacy_compliances === null || bilan.pharmacy_compliances.length === 0
                  ? (<p>Aucune {hasRenamePerm ? 'remarque' : 'conformité'} enregistrée.</p>)
                  : (bilan.pharmacy_compliances.map(conformity => (
                      <ConformityHistory key={conformity.id}>
                        <p>Le {DateTime.fromISO(conformity.created_at).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_SHORT)} par {getAuthor(conformity)}</p>
                        <p>
                          <ColorizeIcon className={`pui-${getComplianceData(conformity)?.kind ?? 'empty'}`}
                                        title={getComplianceData(conformity)?.label ?? 'Non renseigné'} />
                          {' '}{getComplianceData(conformity)?.label ?? 'Non renseigné'}
                        </p>
                        {conformity.details.length > 0
                          ? (<p>Commentaire&thinsp;: {conformity.details}</p>)
                          : (<p>Aucun commentaire</p>)}
                      </ConformityHistory>
                    ))
                  )}
              </>
              <>
                <br />
                <h3 style={{ marginTop: theme.small }}>Récapitulatif des injections</h3>
                {(drugsList(bilan).length !== 0 || injectionsV2List(bilan).length !== 0) && (
                  <>
                    <Table style={{ borderBottom: 'solid 1px' }}>
                      <thead>
                      <TableHeaderGrey>
                        <th></th>
                        <th>Dose</th>
                        <th>Voie</th>
                        <th>Heure administration</th>
                        <th>Ampoulier</th>
                        <th>Agent de terrain</th>
                        <th>Prescrit</th>
                      </TableHeaderGrey>
                      </thead>
                      {injectionsByProtocolList(bilan, false)?.map((p, idxP) => (<>
                        <TableHeaderLightGrey id={'protocol-header-row-' + idxP} colSpan='7'
                                              scope='colgroup'>{p.protocolName}</TableHeaderLightGrey>
                        {p.injections.map((i, idxI) => (
                          <tr key={idxP + '-injection-row-' + idxI}>
                            <td style={{ paddingLeft: '3rem' }}>{i?.drug || 'NR'}</td>
                            <td>{i.medications[0]?.quantity || 'NR'} {getValue(i.medications[0]?.unit, [...MEDIC_UNITS])}</td>
                            <td>{getValue(i.route_of_administration, [...MEDIC_ROUTES]) || 'NR'}</td>
                            <Pre>{DateTime.fromISO(i.started_at)
                              .setLocale('fr-fr')
                              .toLocaleString(DateTime.DATETIME_SHORT)}</Pre>
                            <td>{i.ampoule_holder_identification || 'NR'}</td>
                            <td>{i.created_by?.full_name || 'NR'}</td>
                            <td>{i.prescription_header_id === null ? 'Non' : 'Oui'}</td>
                          </tr>
                        ))}
                      </>))}
                      {injectionsByProtocolList(bilan, true)?.map((p, idxP) => (<>
                        <TableHeaderLightGrey id={'protocol-header-row-' + idxP} colSpan='7'
                                              scope='colgroup'>{p.protocolName}</TableHeaderLightGrey>
                        {p.injections.map((i, idxI) => (
                          <tr key={idxP + '-injection-row-' + idxI}>
                            <td style={{ paddingLeft: '3rem' }}>{i?.drug || 'NR'}</td>
                            <td>{getQuantiteAdministrationInjection(i)}</td>
                            <td>{i.drug_route_name || 'NR'}</td>
                            <Pre>{getHeureAdministrationInjection(i)}</Pre>
                            <td>{i.ampoule_holder_identification || 'NR'}</td>
                            <td>{i.created_by?.full_name || 'NR'}</td>
                            <td>{i.prescription_header_id === null ? 'Non' : 'Oui'}</td>
                          </tr>
                        ))}
                      </>))}
                    </Table>
                  </>
                )}
              </>
              {fluidsList(bilan).length !== 0 && (
                <Table>
                  <thead>
                  <tr>
                    <th>Soluté</th>
                    <th>Ampoulier</th>
                    <th>Dose</th>
                    <th>Date</th>
                  </tr>
                  </thead>
                  {fluidsList(bilan).map((fluid, idx) => (
                    <tr key={idx}>
                      <td>{fluid?.iv_fluids[0]?.name}</td>
                      <td>{fluid?.iv_fluids[0]?.ampoule_holder_identification || 'NR'}</td>
                      <td>{fluid?.iv_fluids[0]?.quantity || 'NR'}</td>
                      <td>
                        {DateTime.fromISO(fluid.started_at).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_SHORT)}
                      </td>
                    </tr>
                  ))}
                </Table>
              )}
              <>
                <br />
                <h3 style={{ marginTop: theme.small }}>Récapitulatif des Prescriptions / ASU de thérapeutiques</h3>
                {prescriptionMedList(bilan).length !== 0 && (
                  <>
                    <Table style={{ borderBottom: 'solid 1px' }}>
                      <thead>
                      <TableHeaderGrey>
                        <th></th>
                        <th>Dose</th>
                        <th>Voie</th>
                        <th>N° Kit</th>
                        <th>Prescripteur</th>
                        <th>Agent de terrain</th>
                        <th>Date</th>
                      </TableHeaderGrey>
                      </thead>
                      {activeMedStates(bilan).map(state => (<>
                        <TableHeaderLightGrey id={state} colSpan='7' scope='colgroup'>
                          {stateHtml(state, 'MEDICATION')}
                        </TableHeaderLightGrey>
                        {prescriptionMedList(bilan, state).map((header, idx) => (
                          <tr key={idx}>
                            <td style={{ paddingLeft: '3rem' }}>{header.prescriptions[0]?.payload?.label}</td>
                            <td>{header.prescriptions[0]?.payload?.dosage} {header.prescriptions[0]?.payload?.unit}</td>
                            <td>{header.prescriptions[0]?.payload?.route}</td>
                            <td>{header.prescriptions[0]?.numero_kit}</td>
                            <td>{getPrescripteur(header)}</td>
                            <td>{getAdministrateur(header.prescriptions[0])}</td>
                            <td>{getHeureAdministrationPrescription(header)}</td>
                          </tr>
                        ))}
                      </>))}
                    </Table>
                  </>
                )}
                <br />
                <h3 style={{ marginTop: theme.small }}>Récapitulatif des Prescriptions / ASU de gestes</h3>
                {prescriptionGestureList(bilan).length !== 0 && (
                  <>
                    <Table style={{ borderBottom: 'solid 1px' }}>
                      <thead>
                      <TableHeaderGrey>
                        <th></th>
                        <th>Description</th>
                        <th>Prescripteur</th>
                        <th>Agent de terrain</th>
                        <th>Date</th>
                      </TableHeaderGrey>
                      </thead>
                      {activeGestureStates(bilan).map(state => (<>
                        <TableHeaderLightGrey id={state} colSpan='6' scope='colgroup'>
                          {stateHtml(state, 'GESTURE')}
                        </TableHeaderLightGrey>
                        {prescriptionGestureList(bilan, state).map((header, idx) => (
                          <tr key={idx}>
                            <td style={{ paddingLeft: '3rem' }}>{header.prescriptions[0]?.payload?.geste}</td>
                            <td>{header.prescriptions[0]?.payload?.description}</td>
                            <td>{getPrescripteur(header)}</td>
                            <td>{getAdministrateur(header.prescriptions[0])}</td>
                            <td>{getHeureAdministrationPrescription(header)}</td>
                          </tr>
                        ))}
                      </>))}
                    </Table>
                  </>
                )}
              </>
            </Container>
          </>
        }
      />
    </CotMed>
  );
};

const CotMed = styled.div`
  margin-top: ${theme.medium};
  margin-left: ${theme.thin};

  label {
    margin-top: ${theme.small};
  }
`;
